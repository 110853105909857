import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../helpers/axios/axios";
import { toast } from "react-toastify";
import {
  LogisticsClientsURL,
  LogisticsCompaniesURL,
  LogisticsPODocketNumber,
  LogisticsPOInward,
  LogisticsReverseDocket,
  LogisticsWarehouse,
  products,
} from "../../helpers/endpoints/api_endpoints";
import Header from "../../views/layout/Header";
import AdminSideBar from "../../views/layout/AdminSideBar";
import { Input, Select } from "antd";
import { Button } from "react-bootstrap";
import { Upload } from "react-feather";
import ImportModal from "./ImportModal";
import DocketModal from "./DocketModal";

const PurchaseOrder = () => {
  const [reservedDockets, setReservedDockets] = useState({
    firstNumber: "",
    lastNumber: "",
  });
  const [docketCount, setDocketCount] = useState(10); // Default count to reserve
  const { Option } = Select;
  const [showImportModal, setShowImportModal] = useState(false);
  const [showDocketModal, setShowDocketModal] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [warehouseList, setWarehouseList] = useState([]);
  const navigate = useNavigate();
  const [productList, setProductList] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleShowModal = () => setShowDocketModal(true);
  const handleCloseModal = () => setShowDocketModal(false);
  console.log(clientList);
  const [formData, setFormData] = useState({
    type: "",
    atm_category: "",
    secondary_type: "",
    client: "",
    company: "",
    remarks: "",
    client_docket_number: "",
    client_invoice_number: "",
    client_invoice_value: "",
    subclient_name: "",
    atm_id: "",
    atm_sno: "",
    from_atm: "",
    to_atm: "",
    eway_bill: "",
    transport_type: "",
    receiver: "",
    from_name: "",
    from_address: "",
    from_pincode: "",
    from_contact: "",
    from_email: "",
    to_name: "",
    to_address: "",
    to_pincode: "",
    to_contact: "",
    to_email: "",
    items: [{ name: "", weight: "", number: "" }],
    state: "",
    client_invoice_date: "",
    warehouse: "",
  });
  console.log(formData);
  // Fetch products with backend search (based on user input)
  const fetchProducts = (searchTerm = "") => {
    axiosInstance.get(`${products}?name=${searchTerm}`).then(
      (response) => {
        console.log(response.data);
        setProductList(response.data); // Set the productList state with fetched data
      },
      (error) => {
        console.log(error);
      }
    );
  };

  // Fetch clients with backend search (based on user input)
  const fetchClients = (searchTerm = "") => {
    axiosInstance.get(`${LogisticsClientsURL}?name=${searchTerm}`).then(
      (response) => {
        console.log(response.data);
        setClientList(response.data);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  // Fetch companies with backend search (based on user input)
  const fetchCompanies = (searchTerm = "") => {
    axiosInstance.get(`${LogisticsCompaniesURL}?name=${searchTerm}`).then(
      (response) => {
        console.log(response.data);
        setCompanyList(response.data);
      },
      (error) => {
        console.log(error);
      }
    );
  };
  useEffect(() => {
    // Fetch all products, clients, and companies initially
    fetchProducts();
    fetchClients();
    fetchCompanies();
  }, []);

  const handleSearchProducts = (value) => {
    fetchProducts(value);
  };

  const handleSearchClients = (value) => {
    console.log(value);
    fetchClients(value);
  };

  const handleSearchCompanies = (value) => {
    fetchCompanies(value);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = (name, value, label) => {
    console.log(name, value, label);

    const selectedValue =
      name === "client"
        ? value
        : label && name === "company"
        ? value
        : label && name === "warehouse"
        ? value
        : label;

    console.log(selectedValue);

    setFormData({ ...formData, [name]: selectedValue });

    // Fetch next docket number when company is selected
    if (name === "company" && value) {
      axiosInstance
        .get(`${LogisticsPODocketNumber}${value}/`)
        .then((response) => {
          console.log(response.data);
          const docketNumber = response?.data?.next_vt_docket_number;
          setFormData((prevData) => ({
            ...prevData,
            vt_docket_number: docketNumber, // Update form with docket number
          }));
        })
        .catch((error) => {
          console.log("Error fetching docket number", error);
        });
    }
  };

  const handleItemChange = (index, field, value) => {
    const newItems = formData?.items?.map((item, i) => {
      if (i === index) {
        let newValue = value;

        // Ensure numeric fields are properly parsed and validated
        if (field === "number") {
          if (value === "") {
            newValue = ""; // Allow the field to be empty
          } else {
            newValue = parseInt(value, 10);

            // Prevent negative values
            if (isNaN(newValue) || newValue < 0) {
              newValue = 0;
            }
          }
        }

        return {
          ...item,
          [field]: newValue,
        };
      }

      return item;
    });

    setFormData((prevFormData) => ({
      ...prevFormData,
      items: newItems,
    }));
  };

  const handleAddItem = () => {
    setFormData({
      ...formData,
      items: [...formData.items, { name: "", weight: "", number: "" }],
    });
  };

  const handleDeleteItem = (index) => {
    const newItems = formData.items.filter((_, i) => i !== index);
    setFormData({ ...formData, items: newItems });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isSubmitting) return; // Prevent further submissions if already submitting

    setIsSubmitting(true); // Set submission status to true

    // Create a new object that only includes client_invoice_date if it's not empty
    const dataToSend = { ...formData };
    if (!dataToSend.client_invoice_date) {
      delete dataToSend.client_invoice_date;
    }

    console.log(dataToSend);
    axiosInstance
      .post(`${LogisticsPOInward}`, dataToSend)
      .then(
        (response) => {
          console.log(response.data);
          toast.success("Form submission successful");
          setFormData({}); // Reset form data
          navigate("/inward-purchase-order-list");
        },
        (error) => {
          console.log(error);
          if (error?.response?.data?.client) {
            toast.error("Client details can not be empty");
          }
          if (error?.response?.data?.company) {
            toast.error("Company details can not be empty");
          }
          if (error?.response?.data?.transport_type) {
            toast.error("Transport Type details can not be empty");
          }
          if (error?.response?.data?.type) {
            toast.error("Type can not be empty");
          }
        }
      )
      .finally(() => {
        setIsSubmitting(false); // Reset submission status after completion
      });
  };

  useEffect(() => {
    axiosInstance.get(`${LogisticsCompaniesURL}`).then(
      (response) => {
        console.log(response.data);
        setCompanyList(response.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  const stateNames = [
    { value: "Andhra Pradesh", label: "Andhra Pradesh" },
    { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
    { value: "Assam", label: "Assam" },
    { value: "Bihar", label: "Bihar" },
    { value: "Chhattisgarh", label: "Chhattisgarh" },
    { value: "Goa", label: "Goa" },
    { value: "Gujarat", label: "Gujarat" },
    { value: "Haryana", label: "Haryana" },
    { value: "Himachal Pradesh", label: "Himachal Pradesh" },
    { value: "Jharkhand", label: "Jharkhand" },
    { value: "Karnataka", label: "Karnataka" },
    { value: "Kerala", label: "Kerala" },
    { value: "Madhya Pradesh", label: "Madhya Pradesh" },
    { value: "Maharashtra", label: "Maharashtra" },
    { value: "Manipur", label: "Manipur" },
    { value: "Meghalaya", label: "Meghalaya" },
    { value: "Mizoram", label: "Mizoram" },
    { value: "Nagaland", label: "Nagaland" },
    { value: "Odisha", label: "Odisha" },
    { value: "Punjab", label: "Punjab" },
    { value: "Rajasthan", label: "Rajasthan" },
    { value: "Sikkim", label: "Sikkim" },
    { value: "Tamil Nadu", label: "Tamil Nadu" },
    { value: "Telangana", label: "Telangana" },
    { value: "Tripura", label: "Tripura" },
    { value: "Uttar Pradesh", label: "Uttar Pradesh" },
    { value: "Uttarakhand", label: "Uttarakhand" },
    { value: "West Bengal", label: "West Bengal" },
  ];

  const typeDropdownData = [
    { value: "1", label: "ATM Movement" },
    { value: "2", label: "Household" },
    { value: "3", label: "3PL" },
    { value: "4", label: "Other" },
  ];
  const ATMCategory = [
    { value: "1", label: "Primary" },
    { value: "2", label: "Secondary" },
  ];
  const ATMType = [
    { value: "1", label: "Pickup" },
    { value: "2", label: "Buyback" },
    { value: "3", label: "Shifting" },
    { value: "4", label: "Others" },
  ];

  const transportType = [
    { value: "1", label: "Air" },
    { value: "2", label: "Shipping" },
    { value: "3", label: "Surface" },
  ];

  const ContentName = productList?.results?.map((product) => ({
    value: product.id,
    label: product.name,
  }));

  const ClientList = clientList?.results?.map((company) => ({
    value: company.id,
    label: company.name,
  }));
  const CompanyList = companyList?.results?.map((company) => ({
    value: company.id,
    label: company.name,
  }));
  const WarehouseList = warehouseList?.results?.map((company) => ({
    value: company.id,
    label: company.location,
  }));
  console.log(ClientList);

  // fetch warehouse data
  useEffect(() => {
    axiosInstance.get(`${LogisticsWarehouse}`).then(
      (response) => {
        console.log(response.data);
        setWarehouseList(response.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);
  return (
    <>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="card-header d-flex justify-content-between align-items-center mb-2">
            <h2 className="card-title mb-2">Purchase Order Form</h2>
            <div>
              <Button onClick={() => setShowImportModal(true)}>
                Import
                <Upload
                  className="feather-download ms-2 mb-2"
                  data-bs-toggle="tooltip"
                  title="feather-download"
                />
              </Button>
              <Button className=" ms-2" onClick={handleShowModal}>
                Reserve Docket Number{" "}
              </Button>
            </div>

            <ImportModal
              show={showImportModal}
              handleClose={() => setShowImportModal(false)}
            />
            <DocketModal
              CompanyList={CompanyList}
              show={showDocketModal}
              handleClose={handleCloseModal}
            />
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-0">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row mb-4 justify-content-around">
                      <div className="col-xl-5 me-4">
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            State<span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-8">
                            <Select
                              className="form-control"
                              showSearch // Enables the search input
                              placeholder="Select State"
                              onChange={(value, option) =>
                                handleSelectChange(
                                  "state",
                                  value,
                                  option.children
                                )
                              }
                              filterOption={(input, option) =>
                                option?.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                            >
                              {stateNames?.map((location) => (
                                <Option
                                  key={location?.value}
                                  value={location?.value}
                                >
                                  {location?.label}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </div>

                        {formData?.type === "ATM Movement" && (
                          <h4 className="card-title">ATM Information</h4>
                        )}
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            Type<span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-8">
                            <Select
                              className="form-control"
                              options={typeDropdownData}
                              placeholder="Select Type"
                              onChange={(value, option) =>
                                handleSelectChange("type", value, option.label)
                              }
                              required
                            />
                          </div>
                        </div>
                        {formData?.type === "ATM Movement" && (
                          <div className="input-block mb-3 row align-items-center">
                            <label className="col-lg-3 col-form-label">
                              ATM Category
                            </label>
                            <div className="col-lg-8">
                              <Select
                                className="form-control"
                                options={ATMCategory}
                                placeholder="Select ATM Category"
                                onChange={(value, option) =>
                                  handleSelectChange(
                                    "atm_category",
                                    value,
                                    option.label
                                  )
                                }
                              />
                            </div>
                          </div>
                        )}
                        {formData?.type === "ATM Movement" &&
                          formData?.atm_category === "Secondary" && (
                            <div className="input-block mb-3 row align-items-center">
                              <label className="col-lg-3 col-form-label">
                                ATM Type
                              </label>
                              <div className="col-lg-8">
                                <Select
                                  className="form-control"
                                  options={ATMType}
                                  placeholder="Select ATM Type"
                                  onChange={(value, option) =>
                                    handleSelectChange(
                                      "secondary_type",
                                      value,
                                      option.label
                                    )
                                  }
                                />
                              </div>
                            </div>
                          )}
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            Client<span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-8">
                            <Select
                              required
                              className="form-control"
                              showSearch
                              placeholder="Search Clients"
                              onSearch={handleSearchClients}
                              onChange={(value, option) =>
                                handleSelectChange("client", value, option)
                              } // Pass the option to access label
                              filterOption={false}
                            >
                              {ClientList?.map((client) => (
                                <Option
                                  key={client.value}
                                  value={client.value}
                                  label={client.label}
                                >
                                  {client.label}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </div>
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            Company Name<span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-8">
                            <Select
                              required
                              className="form-control"
                              showSearch
                              placeholder="Search Companies"
                              onSearch={handleSearchCompanies}
                              onChange={(value, option) =>
                                handleSelectChange("company", value, option)
                              } // Pass both value and option
                              filterOption={false} // Backend search
                            >
                              {CompanyList?.map((company) => (
                                <Option
                                  key={company.value}
                                  value={company.value}
                                  label={company.label}
                                >
                                  {company.label}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </div>
                        {/* <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            Warehouse<span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-8">
                            <Select
                              className="form-control"
                              options={WarehouseList}
                              placeholder="Select Warehouse"
                              onChange={(value, option) =>
                                handleSelectChange(
                                  "warehouse",
                                  value,
                                  option.label
                                )
                              }
                              required={true}
                            />
                          </div>
                        </div> */}
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            Warehouse<span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-8">
                            <Select
                              className="form-control"
                              options={WarehouseList}
                              placeholder="Select Warehouse"
                              showSearch
                              filterOption={(input, option) =>
                                option.label
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              onChange={(value, option) =>
                                handleSelectChange(
                                  "warehouse",
                                  value,
                                  option.label
                                )
                              }
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            Sub Client Name
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="text"
                              className="form-control"
                              name="subclient_name"
                              value={formData?.subclient_name}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            Docket Number
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="text"
                              className="form-control"
                              name="client_docket_number"
                              value={formData?.client_docket_number}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            Invoice Number
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="text"
                              className="form-control"
                              name="client_invoice_number"
                              value={formData?.client_invoice_number}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            Invoice Date
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="date"
                              className="form-control"
                              name="client_invoice_date"
                              value={formData?.client_invoice_date}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            Invoice Value
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="text"
                              className="form-control"
                              name="client_invoice_value"
                              value={formData?.client_invoice_value}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-5 ms-4">
                        {formData?.type === "ATM Movement" && (
                          <h4 className="card-title">ATM Details</h4>
                        )}
                        {formData?.type === "ATM Movement" && (
                          <div className="input-block mb-3 row align-items-center">
                            <label className="col-lg-3 col-form-label">
                              ATM ID
                            </label>
                            <div className="col-lg-8">
                              <input
                                type="text"
                                className="form-control"
                                name="atm_id"
                                value={formData?.atm_id}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        )}
                        {formData?.type === "ATM Movement" && (
                          <div className="input-block mb-3 row align-items-center">
                            <label className="col-lg-3 col-form-label">
                              ATM Serial Number
                            </label>
                            <div className="col-lg-8">
                              <input
                                type="text"
                                className="form-control"
                                name="atm_sno"
                                value={formData?.atm_sno}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        )}
                        {formData?.type === "ATM Movement" && (
                          <div className="input-block mb-3 row align-items-center">
                            <label className="col-lg-3 col-form-label">
                              From ATM ID
                            </label>
                            <div className="col-lg-8">
                              <input
                                type="text"
                                className="form-control"
                                name="from_atm"
                                value={formData?.from_atm}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        )}
                        {formData?.type === "ATM Movement" && (
                          <div className="input-block mb-3 row align-items-center">
                            <label className="col-lg-3 col-form-label">
                              To ATM ID
                            </label>
                            <div className="col-lg-8">
                              <input
                                type="text"
                                className="form-control"
                                name="to_atm"
                                value={formData?.to_atm}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        )}
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            E-way Bill Number
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="text"
                              className="form-control"
                              name="eway_bill"
                              value={formData?.eway_bill}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            Transport Type<span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-8">
                            <Select
                              className="form-control"
                              options={transportType}
                              placeholder="Select Transport Type"
                              onChange={(value, option) =>
                                handleSelectChange(
                                  "transport_type",
                                  value,
                                  option.label
                                )
                              }
                              required
                            />
                          </div>
                        </div>
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            Receiver Name<span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="text"
                              className="form-control"
                              name="receiver"
                              value={formData?.receiver}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            Remarks
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="text"
                              className="form-control"
                              name="remarks"
                              value={formData?.remarks}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            VT Docket Number
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="text"
                              className="form-control"
                              name="vt_docket_number"
                              value={formData?.vt_docket_number}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-4 justify-content-around">
                      <div className="col-xl-5 me-4">
                        <h4 className="card-title">From Address</h4>
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            From Name
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="text"
                              className="form-control"
                              name="from_name"
                              value={formData?.from_name}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            Address<span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-8">
                            <input
                              className="form-control"
                              name="from_address"
                              value={formData?.from_address}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            Pincode<span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="text"
                              className="form-control"
                              name="from_pincode"
                              value={formData?.from_pincode}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            Contact Number
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="text"
                              className="form-control"
                              name="from_contact"
                              value={formData?.from_contact}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            Email
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="email"
                              className="form-control"
                              name="from_email"
                              value={formData?.from_email}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-5 ms-4">
                        <h4 className="card-title">To Address</h4>
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            To Name
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="text"
                              className="form-control"
                              name="to_name"
                              value={formData?.to_name}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            Address<span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-8">
                            <input
                              className="form-control"
                              name="to_address"
                              value={formData?.to_address}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            Pincode<span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="text"
                              className="form-control"
                              name="to_pincode"
                              value={formData?.to_pincode}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            Contact Number
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="text"
                              className="form-control"
                              name="to_contact"
                              value={formData?.to_contact}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            Email
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="email"
                              className="form-control"
                              name="to_email"
                              value={formData?.to_email}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4 justify-content-around">
                      <div className="col-xl-10 mx-auto">
                        <h4 className="card-title">
                          Items<span className="text-danger">*</span>
                        </h4>
                        {formData?.items?.map((item, index) => (
                          <div
                            key={index}
                            className="row align-items-center mb-3"
                          >
                            <div className="col-lg-3">
                              <Select
                                required
                                className="form-control"
                                showSearch
                                placeholder="Search Products"
                                onSearch={handleSearchProducts}
                                onChange={(value, option) =>
                                  handleItemChange(index, "name", value)
                                } // Pass both value and option
                                filterOption={false} // Prevent frontend filtering; rely on backend search
                              >
                                {ContentName?.map((product) => (
                                  <Option
                                    key={product.value}
                                    value={product.value}
                                    label={product.label}
                                  >
                                    {product.label}
                                  </Option>
                                ))}
                              </Select>
                            </div>

                            <div className="col-lg-3">
                              <input
                                type="number" // Set input type to number
                                className="form-control"
                                placeholder="Weight"
                                value={item.weight}
                                min="0" // Set minimum value to 0 to prevent negative numbers
                                onChange={(e) =>
                                  handleItemChange(
                                    index,
                                    "weight",
                                    e.target.value
                                  )
                                }
                                onWheel={(e) => e.target.blur()} // Prevent change on scroll
                                onKeyDown={(e) =>
                                  (e.key === "ArrowUp" ||
                                    e.key === "ArrowDown") &&
                                  e.preventDefault()
                                } // Prevent change on arrow keys
                                required
                              />
                            </div>
                            <div className="col-lg-3">
                              <input
                                type="number"
                                min="0"
                                onWheel={(e) => e.target.blur()} // Prevent change on scroll
                                onKeyDown={(e) =>
                                  (e.key === "ArrowUp" ||
                                    e.key === "ArrowDown") &&
                                  e.preventDefault()
                                } // Prevent change on arrow keys
                                className="form-control"
                                placeholder="Pieces"
                                value={item.number}
                                onChange={(e) =>
                                  handleItemChange(
                                    index,
                                    "number",
                                    e.target.value
                                  )
                                }
                                required
                              />
                            </div>
                            <div className="col-lg-2">
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => handleDeleteItem(index)}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        ))}
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleAddItem}
                        >
                          Add Item
                        </button>
                      </div>
                    </div>
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-success"
                        disabled={isSubmitting} // Disable button when submitting
                      >
                        {isSubmitting ? "Submitting..." : "Submit"}{" "}
                        {/* Show submitting text */}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PurchaseOrder;
