import React, { useEffect, useState } from "react";
import Header from "../../views/layout/Header";
import AdminSideBar from "../../views/layout/AdminSideBar";
import { axiosInstance } from "../../helpers/axios/axios";
import {
  LogisticsApprovalPendingList,
  exportOutwardData,
  outwardFormUrl,
} from "../../helpers/endpoints/api_endpoints";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { Download, Loader } from "react-feather";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Select } from "antd";
import { Link } from "react-router-dom";

function ViewDetailsModal({
  show,
  handleClose,
  updateOutwardInList,
  removeOutwardFromList,
  fetchInwardList,
}) {
  const [formData, setFormData] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const { designation } = useSelector((state) => state.account);
  console.log(designation);
  useEffect(() => {
    if (show) {
      axiosInstance.get(`${outwardFormUrl}${show}/`).then(
        (response) => {
          console.log("viewdetails", response.data);
          setFormData(response.data);
          setOriginalData(response.data); // Save original data
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      setIsEditing(false);
      setFormData(null);
      setOriginalData(null); // Reset original data
    }
  }, [show]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    // Prevent negative numbers
    if (Number(value) < 0) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: 0, // Reset to 0 if a negative number is input
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleSelectChange = (name, value, label) => {
    const selectedValue =
      name === "client" ? value : label && name === "company" ? value : label;
    console.log(selectedValue);
    setFormData({ ...formData, [name]: selectedValue });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const changedData = {};

    // Remove invisible characters and trim
    const normalizedDesignation = designation
      .replace(/[\u200B-\u200D\uFEFF]/g, "")
      .trim();

    console.log("Current Designation: ", normalizedDesignation); // Log the designation value

    if (normalizedDesignation === "Accounts Manager") {
      console.log("Accounts Manager selected");
      changedData.approved_by_accounts_manager = true;
    } else if (normalizedDesignation === "Area Manager") {
      console.log("Area Manager selected");
      changedData.approved_by_area_manager = true;
    } else if (normalizedDesignation === "Country Head") {
      console.log("Country Head selected");
      changedData.approved_by_country_head = true;
    } else if (normalizedDesignation === "Director") {
      console.log("Director selected");
      changedData.approved_by_director = true;
    } else if (normalizedDesignation === "Zonal Manager") {
      console.log("Zonal Manager selected");
      changedData.approved_by_zonal_manager = true;
    } else if (normalizedDesignation === "Finance Manager") {
      console.log("Finance Manager selected");
      changedData.approved_by_finance_manager = true;
    }

    for (let key in formData) {
      if (formData[key] !== originalData[key]) {
        changedData[key] = formData[key];
      }
    }

    console.log("Changed Data: ", changedData);

    axiosInstance.patch(`${outwardFormUrl}${show}/`, changedData).then(
      (response) => {
        console.log(response.data);
        updateOutwardInList(response.data);
        setIsEditing(false);
        fetchInwardList();
        handleClose();
        toast.success("Saved successfully");
      },
      (error) => {
        console.log(error);
      }
    );
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   const changedData = {};

  //   if (designation === "Accounts Manager") {
  //     console.log("test designation");
  //     changedData.approved_by_accounts_manager = true;
  //   }
  //   if (designation === "⁠Area Manager") {
  //     console.log("test designation");
  //     changedData.approved_by_area_manager = true;
  //   }
  //   if (designation === "Country Head") {
  //     console.log("test designation");
  //     changedData.approved_by_country_head = true;
  //   }
  //   if (designation === "Director") {
  //     console.log("test designation");
  //     changedData.approved_by_director = true;
  //   }
  //   if (designation === "⁠Zonal Manager") {
  //     console.log("test designation");
  //     changedData.approved_by_zonal_manager = true;
  //   }
  //   if (designation === "Finance Manager") {
  //     console.log("test designation");
  //     changedData.approved_by_finance_manager = true;
  //   }

  //   for (let key in formData) {
  //     if (formData[key] !== originalData[key]) {
  //       changedData[key] = formData[key];
  //     }
  //   }

  //   console.log("Changed Data: ", changedData);

  //   axiosInstance.patch(`${outwardFormUrl}${show}/`, changedData).then(
  //     (response) => {
  //       console.log(response.data);
  //       updateOutwardInList(response.data);
  //       setIsEditing(false);
  //       fetchInwardList();
  //       handleClose();
  //       toast.success("Saved successfully");
  //     },
  //     (error) => {
  //       console.log(error);
  //     }
  //   );
  // };

  const handleDelete = () => {
    axiosInstance.delete(`${outwardFormUrl}${show}/`).then(
      () => {
        removeOutwardFromList(show);
        setShowDeleteConfirmation(false);
        handleClose();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const openDeleteConfirmation = () => {
    setShowDeleteConfirmation(true);
  };

  const closeDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
  };

  return (
    <>
      <Modal
        centered
        size="lg"
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Additional Activity Cost
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formData ? (
            <>
              {isEditing ? (
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="abortive_charge">
                    <Form.Label className="mb-0 mt-2">
                      Abortive Charge
                    </Form.Label>
                    <Form.Control
                      type="number"
                      min="0"
                      onWheel={(e) => e.target.blur()} // Prevent change on scroll
                      onKeyDown={(e) =>
                        (e.key === "ArrowUp" || e.key === "ArrowDown") &&
                        e.preventDefault()
                      } // Prevent change on arrow keys
                      name="abortive_charge"
                      value={formData.abortive_charge}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group controlId="accessories_charge">
                    <Form.Label className="mb-0 mt-2">
                      Accessories Charge
                    </Form.Label>
                    <Form.Control
                      type="number"
                      min="0"
                      name="accessories_charge"
                      value={formData.accessories_charge}
                      onChange={handleChange}
                      onWheel={(e) => e.target.blur()} // Prevent change on scroll
                      onKeyDown={(e) =>
                        (e.key === "ArrowUp" || e.key === "ArrowDown") &&
                        e.preventDefault()
                      } // Prevent change on arrow keys
                    />
                  </Form.Group>

                  <Form.Group controlId="de_grouting_charge">
                    <Form.Label className="mb-0 mt-2">
                      DE Grouting Charge
                    </Form.Label>
                    <Form.Control
                      type="number"
                      min="0"
                      name="de_grouting_charge"
                      value={formData.de_grouting_charge}
                      onChange={handleChange}
                      onWheel={(e) => e.target.blur()} // Prevent change on scroll
                      onKeyDown={(e) =>
                        (e.key === "ArrowUp" || e.key === "ArrowDown") &&
                        e.preventDefault()
                      } // Prevent change on arrow keys
                    />
                  </Form.Group>

                  <Form.Group controlId="dismantling_charge">
                    <Form.Label className="mb-0 mt-2">
                      Dismantling Charge
                    </Form.Label>
                    <Form.Control
                      type="number"
                      min="0"
                      name="dismantling_charge"
                      value={formData.dismantling_charge}
                      onChange={handleChange}
                      onWheel={(e) => e.target.blur()} // Prevent change on scroll
                      onKeyDown={(e) =>
                        (e.key === "ArrowUp" || e.key === "ArrowDown") &&
                        e.preventDefault()
                      } // Prevent change on arrow keys
                    />
                  </Form.Group>

                  <Form.Group controlId="first_floor_charge">
                    <Form.Label className="mb-0 mt-2">
                      First Floor Charge
                    </Form.Label>
                    <Form.Control
                      type="number"
                      min="0"
                      name="first_floor_charge"
                      value={formData.first_floor_charge}
                      onChange={handleChange}
                      onWheel={(e) => e.target.blur()} // Prevent change on scroll
                      onKeyDown={(e) =>
                        (e.key === "ArrowUp" || e.key === "ArrowDown") &&
                        e.preventDefault()
                      } // Prevent change on arrow keys
                    />
                  </Form.Group>

                  <Form.Group controlId="freight_charge">
                    <Form.Label className="mb-0 mt-2">
                      Freight Charge
                    </Form.Label>
                    <Form.Control
                      type="number"
                      min="0"
                      name="freight_charge"
                      value={formData.freight_charge}
                      onChange={handleChange}
                      onWheel={(e) => e.target.blur()} // Prevent change on scroll
                      onKeyDown={(e) =>
                        (e.key === "ArrowUp" || e.key === "ArrowDown") &&
                        e.preventDefault()
                      } // Prevent change on arrow keys
                    />
                  </Form.Group>

                  <Form.Group controlId="grouting_charge">
                    <Form.Label className="mb-0 mt-2">
                      Grouting Charge
                    </Form.Label>
                    <Form.Control
                      type="number"
                      min="0"
                      name="grouting_charge"
                      value={formData.grouting_charge}
                      onChange={handleChange}
                      onWheel={(e) => e.target.blur()} // Prevent change on scroll
                      onKeyDown={(e) =>
                        (e.key === "ArrowUp" || e.key === "ArrowDown") &&
                        e.preventDefault()
                      } // Prevent change on arrow keys
                    />
                  </Form.Group>

                  <Form.Group controlId="packing_charge">
                    <Form.Label className="mb-0 mt-2">
                      Packing Charge
                    </Form.Label>
                    <Form.Control
                      type="number"
                      min="0"
                      name="packing_charge"
                      value={formData.packing_charge}
                      onChange={handleChange}
                      onWheel={(e) => e.target.blur()} // Prevent change on scroll
                      onKeyDown={(e) =>
                        (e.key === "ArrowUp" || e.key === "ArrowDown") &&
                        e.preventDefault()
                      } // Prevent change on arrow keys
                    />
                  </Form.Group>

                  <Form.Group controlId="union_charge_charge">
                    <Form.Label className="mb-0 mt-2">Union Charge</Form.Label>
                    <Form.Control
                      type="number"
                      min="0"
                      name="union_charge_charge"
                      value={formData.union_charge_charge}
                      onChange={handleChange}
                      onWheel={(e) => e.target.blur()} // Prevent change on scroll
                      onKeyDown={(e) =>
                        (e.key === "ArrowUp" || e.key === "ArrowDown") &&
                        e.preventDefault()
                      } // Prevent change on arrow keys
                    />
                  </Form.Group>

                  <Form.Group controlId="others_charge">
                    <Form.Label className="mb-0 mt-2">Others Charge</Form.Label>
                    <Form.Control
                      type="number"
                      min="0"
                      name="others_charge"
                      value={formData.others_charge}
                      onChange={handleChange}
                      onWheel={(e) => e.target.blur()} // Prevent change on scroll
                      onKeyDown={(e) =>
                        (e.key === "ArrowUp" || e.key === "ArrowDown") &&
                        e.preventDefault()
                      } // Prevent change on arrow keys
                    />
                  </Form.Group>

                  {/* <Button variant="primary" type="submit">
                    Save Changes
                  </Button> */}
                </Form>
              ) : (
                <Table striped bordered hover>
                  <tbody>
                    <tr>
                      <th>Accessories charge</th>
                      <td>
                        {formData.accessories_charge
                          ? formData.accessories_charge
                          : "NA"}
                      </td>{" "}
                    </tr>
                    <tr>
                      <th>Abortive Charge</th>
                      <td>
                        {formData.abortive_charge
                          ? formData.abortive_charge
                          : "NA"}
                      </td>{" "}
                    </tr>
                    <tr>
                      <th>DE Grouting Charge</th>
                      <td>
                        {formData.de_grouting_charge
                          ? formData.de_grouting_charge
                          : "NA"}
                      </td>{" "}
                    </tr>
                    <tr>
                      <th>Dismantling Charge</th>
                      <td>
                        {formData.dismantling_charge
                          ? formData.dismantling_charge
                          : "NA"}
                      </td>{" "}
                    </tr>
                    <tr>
                      <th>First Floor Charge</th>
                      <td>
                        {formData.first_floor_charge
                          ? formData.first_floor_charge
                          : "NA"}
                      </td>{" "}
                    </tr>
                    <tr>
                      <th>Freight Charge</th>
                      <td>
                        {formData.freight_charge
                          ? formData.freight_charge
                          : "NA"}
                      </td>{" "}
                    </tr>
                    <tr>
                      <th>Grouting Charge</th>
                      <td>
                        {formData.grouting_charge
                          ? formData.grouting_charge
                          : "NA"}
                      </td>{" "}
                    </tr>
                    <tr>
                      <th>Packing Charge</th>
                      <td>
                        {formData.grouting_charge
                          ? formData.grouting_charge
                          : "NA"}
                      </td>{" "}
                    </tr>
                    <tr>
                      <th>Union Charge </th>
                      <td>
                        {formData.union_charge_charge
                          ? formData.union_charge_charge
                          : "NA"}
                      </td>{" "}
                    </tr>
                    <tr>
                      <th>Others Charge</th>
                      <td>
                        {formData.others_charge ? formData.others_charge : "NA"}
                      </td>{" "}
                    </tr>
                  </tbody>
                </Table>
              )}
              <div className="d-flex justify-content-end mt-2">
                <Button
                  variant="secondary"
                  onClick={handleClose}
                  className="me-2"
                >
                  Close
                </Button>
                {!isEditing && (
                  <Button variant="success" onClick={handleSubmit}>
                    Approve
                  </Button>
                )}
                {isEditing ? (
                  <Button variant="success" onClick={handleSubmit}>
                    save & approve
                  </Button>
                ) : (
                  <Button variant="primary" onClick={handleEditToggle}>
                    Edit
                  </Button>
                )}
              </div>
            </>
          ) : (
            <p>
              {" "}
              loading...{" "}
              <Loader
                className="feather-loader"
                data-bs-toggle="tooltip"
                title="feather-loader"
              />
            </p>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showDeleteConfirmation}
        onHide={closeDeleteConfirmation}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this outward form?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDeleteConfirmation}>
            Cancel
          </Button>
          {/* <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}
function ViewPendingApprovalDetailsModal({
  show,
  handleClose,
  updateOutwardInListPending,
  removeOutwardFromList,
}) {
  const [formData, setFormData] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [productList, setProductList] = useState([]);
  const [clientList, setClientList] = useState([]);

  useEffect(() => {
    if (show) {
      axiosInstance.get(`${outwardFormUrl}${show}/`).then(
        (response) => {
          console.log(response.data);
          setFormData(response.data);
          setOriginalData(response.data); // Save original data
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      setIsEditing(false);
      setFormData(null);
      setOriginalData(null); // Reset original data
    }
  }, [show]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleSelectChange = (name, value, label) => {
    const selectedValue =
      name === "client" ? value : label && name === "company" ? value : label;
    console.log(selectedValue);
    setFormData({ ...formData, [name]: selectedValue });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const changedData = {};
    for (let key in formData) {
      if (formData[key] !== originalData[key]) {
        changedData[key] = formData[key];
      }
    }

    console.log("Changed Data: ", changedData);

    axiosInstance.patch(`${outwardFormUrl}${show}/`, changedData).then(
      (response) => {
        updateOutwardInListPending(response.data);
        setIsEditing(false);
        handleClose();
        toast.success("Saved successfully");
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleDelete = () => {
    axiosInstance.delete(`${outwardFormUrl}${show}/`).then(
      () => {
        removeOutwardFromList(show);
        setShowDeleteConfirmation(false);
        handleClose();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const openDeleteConfirmation = () => {
    setShowDeleteConfirmation(true);
  };

  const closeDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
  };

  const ClientList = clientList?.results?.map((company) => ({
    value: company.id,
    label: company?.name,
  }));

  const CompanyList = productList?.results?.map((company) => ({
    value: company.id,
    label: company?.name,
  }));
  return (
    <>
      <Modal
        centered
        size="lg"
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Outward Form Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formData ? (
            <>
              {isEditing ? (
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="formClient">
                    <div className="input-block mb-3 row align-items-center">
                      <label className="col-lg-3 col-form-label">Client </label>
                      <div className="col-lg-8">
                        <Select
                          className="form-control"
                          options={ClientList}
                          placeholder="Select Client Name"
                          onChange={(value, option) =>
                            handleSelectChange("client", value, option.label)
                          }
                        />
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group controlId="formCompany">
                    <div className="input-block mb-3 row align-items-center">
                      <label className="col-lg-3 col-form-label">
                        Company Name
                      </label>
                      <div className="col-lg-8">
                        <Select
                          className="form-control"
                          options={CompanyList}
                          placeholder="Select Company Name"
                          onChange={(value, option) =>
                            handleSelectChange("company", value, option.label)
                          }
                        />
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group controlId="formDocketNumber">
                    <Form.Label className="mb-0 mt-2">Docket Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="client_docket_number"
                      value={formData.client_docket_number}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formInvoiceNumber">
                    <Form.Label className="mb-0 mt-2">
                      Invoice Number
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="client_invoice_number"
                      value={formData.client_invoice_number}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formInvoiceValue">
                    <Form.Label className="mb-0 mt-2">Invoice Value</Form.Label>
                    <Form.Control
                      type="text"
                      name="client_invoice_value"
                      value={formData.client_invoice_value}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formVtDocketNumber">
                    <Form.Label className="mb-0 mt-2">
                      VT Docket Number
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="vt_docket_number"
                      value={formData.vt_docket_number}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formMode">
                    <Form.Label className="mb-0 mt-2">Mode</Form.Label>
                    <Form.Control
                      type="text"
                      name="mode"
                      value={formData.mode}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formSender">
                    <Form.Label className="mb-0 mt-2">Sender</Form.Label>
                    <Form.Control
                      type="text"
                      name="sender"
                      value={formData.sender}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formVehicleNumber">
                    <Form.Label className="mb-0 mt-2">
                      Vehicle Number
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="vehicle_number"
                      value={formData.vehicle_number}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formVehicleType">
                    <Form.Label className="mb-0 mt-2">Vehicle Type</Form.Label>
                    <Form.Control
                      type="text"
                      name="vehicle_type"
                      value={formData.vehicle_type}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formDriverStaffName">
                    <Form.Label className="mb-0 mt-2">
                      Driver Staff Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="driver_staff_name"
                      value={formData.driver_staff_name}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formOmNumber">
                    <Form.Label className="mb-0 mt-2">OM Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="om_number"
                      value={formData.om_number}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formActivityCost">
                    <Form.Label className="mb-0 mt-2">Activity Cost</Form.Label>
                    <Form.Control
                      type="text"
                      name="activity_cost"
                      value={formData.activity_cost}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formFromAddress">
                    <Form.Label className="mb-0 mt-2">From Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="from_address"
                      value={formData.from_address}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formFromPincode">
                    <Form.Label className="mb-0 mt-2">From Pincode</Form.Label>
                    <Form.Control
                      type="text"
                      name="from_pincode"
                      value={formData.from_pincode}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formFromContact">
                    <Form.Label className="mb-0 mt-2">From Contact</Form.Label>
                    <Form.Control
                      type="text"
                      name="from_contact"
                      value={formData.from_contact}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formFromEmail">
                    <Form.Label className="mb-0 mt-2">From Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="from_email"
                      value={formData.from_email}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formToAddress">
                    <Form.Label className="mb-0 mt-2">To Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="to_address"
                      value={formData.to_address}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formToPincode">
                    <Form.Label className="mb-0 mt-2">To Pincode</Form.Label>
                    <Form.Control
                      type="text"
                      name="to_pincode"
                      value={formData.to_pincode}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formToContact">
                    <Form.Label className="mb-0 mt-2">To Contact</Form.Label>
                    <Form.Control
                      type="text"
                      name="to_contact"
                      value={formData.to_contact}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formToEmail">
                    <Form.Label className="mb-0 mt-2">To Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="to_email"
                      value={formData.to_email}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formAbortive">
                    <Form.Check
                      type="checkbox"
                      name="abortive"
                      label="Abortive"
                      checked={formData.abortive}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formAccessories">
                    <Form.Check
                      type="checkbox"
                      name="accessories"
                      label="Accessories"
                      checked={formData.accessories}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formDismantling">
                    <Form.Check
                      type="checkbox"
                      name="dismantling"
                      label="Dismantling"
                      checked={formData.dismantling}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formFirstFloor">
                    <Form.Check
                      type="checkbox"
                      name="first_floor"
                      label="First Floor"
                      checked={formData.first_floor}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formFreight">
                    <Form.Check
                      type="checkbox"
                      name="freight"
                      label="Freight"
                      checked={formData.freight}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formDeGrouting">
                    <Form.Check
                      type="checkbox"
                      name="de_grouting"
                      label="De Grouting"
                      checked={formData.de_grouting}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formGrouting">
                    <Form.Check
                      type="checkbox"
                      name="grouting"
                      label="Grouting"
                      checked={formData.grouting}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formOthers">
                    <Form.Check
                      type="checkbox"
                      name="others"
                      label="Others"
                      checked={formData.others}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formPacking">
                    <Form.Check
                      type="checkbox"
                      name="packing"
                      label="Packing"
                      checked={formData.packing}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formUnionCharge">
                    <Form.Check
                      type="checkbox"
                      name="union_charge"
                      label="Union Charge"
                      checked={formData.union_charge}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formRemarks">
                    <Form.Label className="mb-0 mt-2">Remarks</Form.Label>
                    <Form.Control
                      type="text"
                      name="remarks"
                      value={formData.remarks}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formSpecify">
                    <Form.Label className="mb-0 mt-2">Specify</Form.Label>
                    <Form.Control
                      type="text"
                      name="specify"
                      value={formData.specify}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  {formData.outward_products &&
                    formData.outward_products.length > 0 && (
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Product Name</th>
                            <th>Weight</th>
                            <th>Pieces</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formData.outward_products.map((product) => (
                            <tr key={product.id}>
                              <td>{product.ow_product_name?.name || "N/A"}</td>
                              <td>{product.weight || "N/A"}</td>
                              <td>{product.pieces || "N/A"}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}

                  {/* <Button variant="primary" type="submit">
                    Save Changes
                  </Button> */}
                </Form>
              ) : (
                <Table striped bordered hover>
                  <tbody>
                    <tr>
                      <th>Client</th>
                      <td>{formData.client_details?.name}</td>
                    </tr>
                    <tr>
                      <th>Company</th>
                      <td>{formData.company_details?.name}</td>
                    </tr>
                    <tr>
                      <th>Docket Number</th>
                      <td>{formData.client_docket_number}</td>
                    </tr>
                    <tr>
                      <th>Invoice Number</th>
                      <td>{formData.client_invoice_number}</td>
                    </tr>
                    <tr>
                      <th>Invoice Value</th>
                      <td>{formData.client_invoice_value}</td>
                    </tr>
                    <tr>
                      <th>VT Docket Number</th>
                      <td>{formData.vt_docket_number}</td>
                    </tr>
                    <tr>
                      <th>Mode</th>
                      <td>{formData.mode}</td>
                    </tr>
                    <tr>
                      <th>Sender</th>
                      <td>{formData.sender}</td>
                    </tr>
                    <tr>
                      <th>Vehicle Number</th>
                      <td>{formData.vehicle_number}</td>
                    </tr>
                    <tr>
                      <th>Vehicle Type</th>
                      <td>{formData.vehicle_type}</td>
                    </tr>
                    <tr>
                      <th>Driver Staff Name</th>
                      <td>{formData.driver_staff_name}</td>
                    </tr>
                    <tr>
                      <th>OM Number</th>
                      <td>{formData.om_number}</td>
                    </tr>
                    <tr>
                      <th>Activity Cost</th>
                      <td>{formData.activity_cost}</td>
                    </tr>
                    <tr>
                      <th>From Address</th>
                      <td>{formData.from_address}</td>
                    </tr>
                    <tr>
                      <th>From Pincode</th>
                      <td>{formData.from_pincode}</td>
                    </tr>
                    <tr>
                      <th>From Contact</th>
                      <td>{formData.from_contact}</td>
                    </tr>
                    <tr>
                      <th>From Email</th>
                      <td>{formData.from_email}</td>
                    </tr>
                    <tr>
                      <th>To Address</th>
                      <td>{formData.to_address}</td>
                    </tr>
                    <tr>
                      <th>To Pincode</th>
                      <td>{formData.to_pincode}</td>
                    </tr>
                    <tr>
                      <th>To Contact</th>
                      <td>{formData.to_contact}</td>
                    </tr>
                    <tr>
                      <th>To Email</th>
                      <td>{formData.to_email}</td>
                    </tr>
                    <tr>
                      <th>Abortive</th>
                      <td>{formData.abortive ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <th>Accessories</th>
                      <td>{formData.accessories ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <th>Dismantling</th>
                      <td>{formData.dismantling ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <th>First Floor</th>
                      <td>{formData.first_floor ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <th>Freight</th>
                      <td>{formData.freight ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <th>De Grouting</th>
                      <td>{formData.de_grouting ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <th>Grouting</th>
                      <td>{formData.grouting ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <th>Others</th>
                      <td>{formData.others ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <th>Packing</th>
                      <td>{formData.packing ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <th>Union Charge</th>
                      <td>{formData.union_charge ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <th>Remarks</th>
                      <td>{formData.remarks}</td>
                    </tr>
                    <tr>
                      <th>Specify</th>
                      <td>{formData.specify}</td>
                    </tr>
                    <tr>
                      <th>Products</th>
                      <td>
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Pieces</th>
                              <th>Weight</th>
                            </tr>
                          </thead>
                          <tbody>
                            {formData.outward_products.map((product, index) => (
                              <tr key={index}>
                                <td>{product.ow_product_name?.name}</td>
                                <td>{product.pieces}</td>
                                <td>{product.weight}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <th>Photos</th>
                      <td>
                        <Table striped bordered hover>
                          <tbody className="d-flex flex-wrap align-items-center justify-content-center">
                            {formData.photos.length > 0
                              ? formData.photos.map((product, index) => (
                                  <div key={index}>
                                    <div>
                                      <a
                                        href={product.photos} // Use href for external links
                                        className="btn btn-success text-white"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Image {index + 1}
                                      </a>
                                    </div>
                                  </div>
                                ))
                              : "NA"}
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <th>POD</th>
                      <td>
                        <Table striped bordered hover>
                          <tbody className="d-flex flex-wrap align-items-center justify-content-center">
                            {formData.photos.length > 0
                              ? formData.pod.map((product, index) => (
                                  <div key={index}>
                                    <div>
                                      <a
                                        href={product.photos} // Use href for external links
                                        className="btn btn-success text-white"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Image {index + 1}
                                      </a>
                                    </div>
                                  </div>
                                ))
                              : "NA"}
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <th>Approval Mail</th>
                      <td>
                        <Table striped bordered hover>
                          <tbody className="d-flex flex-wrap align-items-center justify-content-center">
                            {formData.photos.length > 0
                              ? formData.approval_mail.map((product, index) => (
                                  <div key={index}>
                                    <div>
                                      <Link
                                        to={product.photos}
                                        className="btn btn-success text-white"
                                      >
                                        File {index + 1}
                                      </Link>
                                    </div>
                                  </div>
                                ))
                              : "NA"}
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              )}
              <div className="d-flex justify-content-end mt-2">
                <Button
                  variant="secondary"
                  onClick={handleClose}
                  className="me-2"
                >
                  Close
                </Button>
                {/* {isEditing ? (
                  <Button variant="success" onClick={handleSubmit}>
                    Save
                  </Button>
                ) : (
                  <Button variant="primary" onClick={handleEditToggle}>
                    Edit
                  </Button>
                )} */}
                {/* <Button
                  variant="danger"
                  onClick={openDeleteConfirmation}
                  className="ms-2"
                >
                  Delete
                </Button> */}
              </div>
            </>
          ) : (
            <p>
              {" "}
              loading...{" "}
              <Loader
                className="feather-loader"
                data-bs-toggle="tooltip"
                title="feather-loader"
              />
            </p>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showDeleteConfirmation}
        onHide={closeDeleteConfirmation}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this outward form?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDeleteConfirmation}>
            Cancel
          </Button>
          {/* <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

// const ApproveOutward = () => {
//   const [inwardList, setInwardList] = useState([]);
//   const [show, setShow] = useState(false);
//   const [showPendingApprovalList, setShowPendingApprovalList] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);

//   useEffect(() => {
//     fetchInwardList();
//   }, []);

//   const fetchInwardList = async (page = 1) => {
//     setIsLoading(true);
//     try {
//       const response = await axiosInstance.get(LogisticsApprovalPendingList, {
//         params: { page },
//       });
//       setInwardList(response.data);
//       setTotalPages(Math.ceil(response.data.count / 20)); // Assuming 20 items per page
//     } catch (error) {
//       console.error("Error fetching inward list: ", error);
//     }
//     setIsLoading(false);
//   };

//   const handlePageChange = (newPage) => {
//     setCurrentPage(newPage);
//     fetchInwardList(newPage);
//   };

//   const handleClose = () => setShow(false);
//   const handlePendingApprovalListModalClose = () =>
//     setShowPendingApprovalList(false);
//   const handleShow = (id) => setShow(id);
//   const handlePendingApprovalListModalShow = (id) =>
//     setShowPendingApprovalList(id);

//   const updateOutwardInList = (updatedOutward) => {
//     setInwardList((prevList) => {
//       return {
//         ...prevList,
//         results: prevList?.results?.map((outward) =>
//           outward.id === updatedOutward.id ? updatedOutward : outward
//         ),
//       };
//     });
//   };

//   const updateOutwardInListPending = (updatedOutward) => {
//     setInwardList((prevList) => {
//       return {
//         ...prevList,
//         results: prevList?.results?.map((outward) =>
//           outward.id === updatedOutward.id ? updatedOutward : outward
//         ),
//       };
//     });
//   };

//   const removeOutwardFromList = (id) => {
//     setInwardList((prevList) => {
//       return {
//         ...prevList,
//         results: prevList?.results?.filter((outward) => outward.id !== id),
//       };
//     });
//   };

//   return (
//     <>
//       <Header />
//       <AdminSideBar />
//       <div className="page-wrapper">
//         <div className="content container-fluid">
//           <div className="d-flex justify-content-between align-items-center mb-2">
//             <h2 className="text-start">Pending Approve List</h2>
//             {/* <Button
//               onClick={handleExport}
//               variant="primary"
//               className="btn btn-primary btn-sm p-2"
//             >
//               Export To Excel
//             </Button> */}
//           </div>
//           <Table striped bordered hover className="text-center">
//             <thead className="table-secondary">
//               <tr>
//                 <th>No.</th>
//                 <th>Name</th>
//                 <th>Docket Number</th>
//                 <th> VT Docket Number</th>
//                 <th>Invoice Number</th>
//                 <th>Actions</th>
//               </tr>
//             </thead>
//             <tbody>
//               {!isLoading ? (
//                 inwardList.count > 0 ? (
//                   inwardList?.results?.map((item, index) => (
//                     <tr key={item.id}>
//                       <td>{(currentPage - 1) * 20 + index + 1}</td>
//                       <td>{item?.client_details?.name || "Not available"}</td>
//                       <td>{item.client_docket_number || "Not Available"}</td>
//                       <td>{item.vt_docket_number || "Not Available"}</td>
//                       <td>{item.client_invoice_number || "Not Available"}</td>
//                       <td>
//                         <Button
//                           variant="primary"
//                           className="btn btn-sm ms-1"
//                           onClick={() => handleShow(item.id)}
//                         >
//                           Verify & Approve
//                         </Button>
//                         <Button
//                           variant="primary"
//                           className="btn btn-sm ms-1"
//                           onClick={() =>
//                             handlePendingApprovalListModalShow(item.id)
//                           }
//                         >
//                           View Details
//                         </Button>
//                       </td>
//                     </tr>
//                   ))
//                 ) : (
//                   <tr>
//                     <td colSpan="6">No data</td>
//                   </tr>
//                 )
//               ) : (
//                 <tr>
//                   <td colSpan="6">
//                     {" "}
//                     loading...{" "}
//                     <Loader
//                       className="feather-loader"
//                       data-bs-toggle="tooltip"
//                       title="feather-loader"
//                     />
//                   </td>
//                 </tr>
//               )}
//             </tbody>
//           </Table>
//           <div className="d-flex justify-content-between">
//             <Button
//               variant="secondary"
//               onClick={() => handlePageChange(currentPage - 1)}
//               disabled={currentPage === 1}
//             >
//               Previous
//             </Button>
//             <span>
//               Page {currentPage} of {totalPages}
//             </span>
//             <Button
//               variant="secondary"
//               onClick={() => handlePageChange(currentPage + 1)}
//               disabled={currentPage === totalPages}
//             >
//               Next
//             </Button>
//           </div>
//           <ViewDetailsModal
//             show={show}
//             handleClose={handleClose}
//             updateOutwardInList={updateOutwardInList}
//             removeOutwardFromList={removeOutwardFromList}
//             fetchInwardList={fetchInwardList}
//           />
//           <ViewPendingApprovalDetailsModal
//             show={showPendingApprovalList}
//             handleClose={handlePendingApprovalListModalClose}
//             updateOutwardInListPending={updateOutwardInListPending}
//             removeOutwardFromList={removeOutwardFromList}
//           />
//         </div>
//       </div>
//     </>
//   );
// };
const ApproveOutward = () => {
  const [inwardList, setInwardList] = useState([]);
  const [show, setShow] = useState(false);
  const [showPendingApprovalList, setShowPendingApprovalList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [vtDocketNumber, setVtDocketNumber] = useState(""); // Added state for vt_docket_number

  useEffect(() => {
    fetchInwardList();
  }, []);

  const fetchInwardList = async (page = 1) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(LogisticsApprovalPendingList, {
        params: {
          page,
          vt_docket_number: vtDocketNumber || undefined, // Include vt_docket_number filter
        },
      });
      setInwardList(response.data);
      setTotalPages(Math.ceil(response.data.count / 20)); // Assuming 20 items per page
    } catch (error) {
      console.error("Error fetching inward list: ", error);
    }
    setIsLoading(false);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchInwardList(newPage);
  };

  const handleFilterChange = () => {
    fetchInwardList(1); // Trigger API call with updated filters
  };

  const handleClose = () => setShow(false);
  const handlePendingApprovalListModalClose = () =>
    setShowPendingApprovalList(false);
  const handleShow = (id) => setShow(id);
  const handlePendingApprovalListModalShow = (id) =>
    setShowPendingApprovalList(id);

  const updateOutwardInList = (updatedOutward) => {
    setInwardList((prevList) => {
      return {
        ...prevList,
        results: prevList?.results?.map((outward) =>
          outward.id === updatedOutward.id ? updatedOutward : outward
        ),
      };
    });
  };

  const updateOutwardInListPending = (updatedOutward) => {
    setInwardList((prevList) => {
      return {
        ...prevList,
        results: prevList?.results?.map((outward) =>
          outward.id === updatedOutward.id ? updatedOutward : outward
        ),
      };
    });
  };

  const removeOutwardFromList = (id) => {
    setInwardList((prevList) => {
      return {
        ...prevList,
        results: prevList?.results?.filter((outward) => outward.id !== id),
      };
    });
  };
  // Automatically apply filter when vtDocketNumber changes
  useEffect(() => {
    fetchInwardList(1); // Reset to page 1 whenever filters change
  }, [vtDocketNumber]);
  return (
    <>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h2 className="text-start">Pending Approve List</h2>
          </div>

          {/* Filter Section */}
          <div className="d-flex mb-3 align-items-center justify-content-end">
            <Form.Control
              type="text"
              placeholder="Enter VT Docket Number"
              value={vtDocketNumber}
              onChange={(e) => setVtDocketNumber(e.target.value)}
              className="me-2 w-50"
            />
            <Button variant="primary" onClick={handleFilterChange}>
              Apply Filter
            </Button>
          </div>

          <Table striped bordered hover className="text-center">
            <thead className="table-secondary">
              <tr>
                <th>No.</th>
                <th>Name</th>
                <th>Docket Number</th>
                <th>VT Docket Number</th>
                <th>Invoice Number</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {!isLoading ? (
                inwardList.count > 0 ? (
                  inwardList?.results?.map((item, index) => (
                    <tr key={item.id}>
                      <td>{(currentPage - 1) * 20 + index + 1}</td>
                      <td>{item?.client_details?.name || "Not available"}</td>
                      <td>{item.client_docket_number || "Not Available"}</td>
                      <td>{item.vt_docket_number || "Not Available"}</td>
                      <td>{item.client_invoice_number || "Not Available"}</td>
                      <td>
                        <Button
                          variant="primary"
                          className="btn btn-sm ms-1"
                          onClick={() => handleShow(item.id)}
                        >
                          Verify & Approve
                        </Button>
                        <Button
                          variant="primary"
                          className="btn btn-sm ms-1"
                          onClick={() =>
                            handlePendingApprovalListModalShow(item.id)
                          }
                        >
                          View Details
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6">No data</td>
                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan="6">
                    loading...
                    <Loader
                      className="feather-loader"
                      data-bs-toggle="tooltip"
                      title="feather-loader"
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          <div className="d-flex justify-content-between">
            <Button
              variant="secondary"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <Button
              variant="secondary"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </div>

          <ViewDetailsModal
            show={show}
            handleClose={handleClose}
            updateOutwardInList={updateOutwardInList}
            removeOutwardFromList={removeOutwardFromList}
            fetchInwardList={fetchInwardList}
          />
          <ViewPendingApprovalDetailsModal
            show={showPendingApprovalList}
            handleClose={handlePendingApprovalListModalClose}
            updateOutwardInListPending={updateOutwardInListPending}
            removeOutwardFromList={removeOutwardFromList}
          />
        </div>
      </div>
    </>
  );
};

export default ApproveOutward;
